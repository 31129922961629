<template>
    <div class="not-found">
        <a-result status="404" title="404" sub-title="抱歉，您要访问的页面不存在">
            <template #extra>
                <a-button type="primary" @click="toHome">回到主页</a-button>
            </template>
        </a-result>
    </div>
</template>
<script>
    import { CloseCircleOutlined } from "@ant-design/icons-vue";
    import { useRouter, useRoute } from "vue-router";
        export default ({
        components: {
            CloseCircleOutlined,
        },
        setup() {
            const router = useRouter();
            function toHome() {
                router.push({ name: 'Home'});
            }
    
            return {
                toHome
            }
        }
    });
</script>
<style>
    .not-found {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>